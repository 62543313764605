import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  useGetNotificationsInfiniteQuery,
  usePostReadAllNotificationsMutation,
} from "@/api/queries/notificationsQueries";
import { usePostReadNotificationsMutation } from "@/api/queries/notificationsQueries";
import NotificationsList from "@/components/features/notifications/NotificationsList";
import NotificationsSheetFooter from "@/components/features/notifications/NotificationsSheetFooter";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet/Sheet";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { Dispatch, SetStateAction, useCallback } from "react";

type NotificationSheetProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

function NotificationsSheet(props: NotificationSheetProps) {
  const { open, onOpenChange, ...rest } = props;
  const breakpoint = useMediaQueryHook("sm");

  const getNotificationsQuery = useGetNotificationsInfiniteQuery();
  const readNotificationsMutation = usePostReadNotificationsMutation();
  const readAllNotificationsMutation = usePostReadAllNotificationsMutation();

  const { data: notifications } = useInfiniteQueryResult(
    getNotificationsQuery.data,
  );

  const makeNotificationsRead = useCallback((array: number[]) => {
    readNotificationsMutation.mutateAsync({ arr: array });
  }, []);

  const makeAllVisibleNotificationsRead = useCallback(() => {
    readNotificationsMutation.mutateAsync({
      arr: notifications.map((m) => m.id),
    });
  }, [notifications]);

  const makeAllNotificationsRead = useCallback(() => {
    readAllNotificationsMutation.mutateAsync();
  }, []);

  if (breakpoint) {
    return (
      <Drawer {...rest} open={open} onOpenChange={onOpenChange}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle className={"sr-only"}>Powiadomienia</DrawerTitle>
          </DrawerHeader>
          <DrawerBody className={"flex flex-col gap-2"}>
            <NotificationsList
              {...getNotificationsQuery}
              makeNotificationsRead={makeNotificationsRead}
            />
          </DrawerBody>
          <DrawerFooter className={"flex flex-row justify-end"}>
            <NotificationsSheetFooter
              makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
              makeAllNotificationsRead={makeAllNotificationsRead}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Sheet {...rest} open={open} onOpenChange={onOpenChange}>
      <SheetContent className={"max-w-[450px]"} side={"right"}>
        <SheetHeader>
          <SheetTitle>Powiadomienia</SheetTitle>
        </SheetHeader>
        <SheetBody className={"flex flex-col gap-2"}>
          <NotificationsList
            {...getNotificationsQuery}
            makeNotificationsRead={makeNotificationsRead}
          />
        </SheetBody>
        <SheetFooter className={"flex flex-row justify-end"}>
          <NotificationsSheetFooter
            makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
            makeAllNotificationsRead={makeAllNotificationsRead}
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
NotificationsSheet.displayName = "NotificationSheet";

export { NotificationsSheet };
