import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { CheckCheck, Settings, Settings2 } from "lucide-react";
import { useNavigate } from "react-router";

type NotificationsSheetFooterProps = {
  makeAllVisibleNotificationsRead: () => void;
  makeAllNotificationsRead: () => void;
};

export default function NotificationsSheetFooter(
  props: NotificationsSheetFooterProps,
) {
  const { makeAllVisibleNotificationsRead, makeAllNotificationsRead } = props;
  const navigate = useNavigate();

  const navigateNotificationSettings = () => {
    navigate("/settings");
  };

  return (
    <div className={"flex w-full justify-between"}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            iconPosition={"only"}
            icon={<Settings />}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={makeAllNotificationsRead}>
              <CheckCheck className={"h-4 w-4"} />
              Zaznacz wszystkie jako przeczytane
            </DropdownMenuItem>
            <DropdownMenuItem onClick={navigateNotificationSettings}>
              <Settings className={"h-4 w-4"} />
              Zmień ustawienia powiadomień
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        onClick={makeAllVisibleNotificationsRead}
        size={"sm"}
        variant={"ghost"}
        variantColor={"brand"}
        iconPosition={"left"}
        icon={<CheckCheck />}
      >
        Zaznacz jako przeczytane
      </Button>
    </div>
  );
}
