import { axiosInstance } from "@/api/api";
import {
  NotificationI,
  ReadNotificationsI,
  UnreadNotificationsCountI,
} from "@/types/notifications";
import { PaginationI } from "@/types/utils";

export const getNotifications = async (
  params?: PaginationI,
): Promise<NotificationI[]> => {
  return await axiosInstance.get("/api/notification", {
    params: params,
  });
};

export const readNotifications = async (
  data?: ReadNotificationsI,
): Promise<string> => {
  return await axiosInstance.post("/api/notification/read", data);
};

export const readAllNotifications = async (): Promise<string> => {
  return await axiosInstance.post("/api/notification/readAll");
};

export const getUnreadNotificationsCount = async (): Promise<
  UnreadNotificationsCountI[]
> => {
  return await axiosInstance.get("/api/notification/unreadCnt");
};

export const subscribeWebPush = async (data: {
  subscription: PushSubscription;
}): Promise<string> => {
  return await axiosInstance.post("/api/notification/subscribe", data);
};

export const unsubscribeWebPush = async (data: {
  subscription: PushSubscription;
}): Promise<string> => {
  return await axiosInstance.post("/api/notification/unsubscribe", data);
};
