import { useGetUnreadNotificationsCountQuery } from "@/api/queries/notificationsQueries";
import { NotificationsSheet } from "@/components/features/notifications/NotificationsSheet";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import Chip from "@/components/ui/chip/chip";
import { useCredentials } from "@/store/authStore";
import { format } from "date-fns";
import { Bell } from "lucide-react";
import { useState } from "react";

export default function HomeHeader() {
  const { name, surname } = useCredentials();
  const [time, setTime] = useState<string>(format(new Date(), "HH:mm"));

  const [open, onOpenChange] = useState<boolean>(false);
  setTimeout(() => {
    setTime(format(new Date(), "HH:mm"));
  }, 15000);

  const { data } = useGetUnreadNotificationsCountQuery("all");

  return (
    <div
      className={
        "relative flex w-full flex-row items-center justify-between rounded-lg border border-border bg-bg-container p-4"
      }
    >
      <NotificationsSheet onOpenChange={onOpenChange} open={open} />
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-3xl font-semibold text-fg-primary"}>{time}</h2>
        <Badge variant={"muted"}>
          {name} {surname}
        </Badge>
      </div>
      <div>
        <Chip className={"w-full"} isInvisible={data === 0} content={data}>
          <Button
            onClick={() => onOpenChange(true)}
            variant={"flat"}
            variantColor={"muted"}
            iconPosition={"left"}
            icon={<Bell />}
            size={"sm"}
          >
            Powiadomienia
          </Button>
        </Chip>
      </div>
    </div>
  );
}
