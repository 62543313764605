import { phoneRegex } from "@/lib/regex";
import { InferType, boolean, date, number, object, string } from "yup";

const UserAddSchema = object({
  name: string().required("Wpisz imię"),
  surname: string().required("Wpisz nazwisko"),
  email: string()
    .email("Niepoprawny adres email")
    .required("Wpisz adres email"),
  lvl: string().required("Wybierz poziom dostępu"),
  phone: string()
    .matches(phoneRegex, {
      message: "Niepoprawny numer telefonu",
      excludeEmptyString: true,
    })
    .optional()
    .trim(),
  birthdate: date()
    .max(new Date(), "musi być data przeszłą")
    .nullable()
    .optional(),
  password: string().min(8).required(),
});
type UserAddSchemaType = InferType<typeof UserAddSchema>;

const UserEditSchema = object({
  id: number().required(),
  name: string().required("Wpisz imię"),
  surname: string().required("Wpisz nazwisko"),
  email: string()
    .email("Niepoprawny adres email")
    .required("Wpisz adres email"),
  lvl: string().required("Wybierz poziom dostępu"),
  tel: string()
    .matches(phoneRegex, {
      message: "Niepoprawny numer telefonu",
      excludeEmptyString: true,
    })
    .optional()
    .trim(),
  birthdate: date()
    .max(new Date(), "musi być data przeszłą")
    .nullable()
    .optional(),
  active: boolean().optional(),
});
type UserEditSchemaType = InferType<typeof UserEditSchema> & {
  force_password_change?: boolean;
};

const UserEditSchemaCast = UserEditSchema;
export { UserAddSchema, UserEditSchema, UserEditSchemaCast };

export type { UserAddSchemaType, UserEditSchemaType };
