import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { ReactNode, RefObject, forwardRef, useMemo } from "react";

const colorVariants = {
  solid: {
    brand: "bg-bg-brand text-fg-brand-on [&>*]:stroke-fg-brand-on",
    accent: "bg-bg-accent text-fg-accent-on [&>*]:stroke-fg-accent-on",
    muted: "bg-bg-muted text-fg-primary [&>*]:stroke-fg-primary",
    success: "bg-bg-success text-fg-success-on [&>*]:stroke-fg-success-on",
    warning: "bg-bg-warning text-fg-warning-on [&>*]:stroke-fg-warning-on",
    destructive:
      "bg-bg-destructive text-fg-destructive-on [&>*]:stroke-fg-destructive-on",
  },
  flat: {
    brand: "bg-bg-brand-subtle text-fg-brand [&>*]:stroke-fg-brand",
    accent: "bg-bg-accent-subtle text-fg-accent [&>*]:stroke-fg-accent",
    muted: "bg-bg-muted-subtle text-fg-primary [&>*]:stroke-fg-primary",
    success: "bg-bg-success-subtle text-fg-success [&>*]:stroke-fg-success",
    warning: "bg-bg-warning-subtle text-fg-warning [&>*]:stroke-fg-warning",
    destructive:
      "bg-bg-destructive-subtle text-fg-destructive [&>*]:stroke-fg-destructive",
  },
};

const ChipVariants = cva(
  "flex z-10 flex-wrap absolute box-border origin-center scale-100 select-none  place-content-center items-center font-regular text-inherit select-none whitespace-nowrap rounded-md opacity-100 subpixel-antialiased data-[invisible=true]:scale-0 data-[invisible=true]:opacity-0",
  {
    variants: {
      variant: {
        solid: "",
        flat: "",
      },
      variantColor: {
        brand: "bg-bg-brand text-fg-brand-on",
        accent: "bg-bg-accent text-fg-accent-on",
        muted: "bg-bg-muted text-fg-primary",
        success: "bg-bg-success text-fg-success-on",
        warning: "bg-bg-warning text-fg-warning-on",
        destructive: "bg-bg-destructive text-fg-destructive-on",
      },

      size: {
        sm: "px-1 text-xs",
        md: "px-1 text-xs",
        lg: "px-1 text-sm",
      },
      isInvisible: {
        true: "",
      },
      isOneChar: {
        true: "px-0",
      },
      isDot: {
        true: "",
      },
      placement: {
        "top-right": "",
        "top-left": "",
        "bottom-right": "",
        "bottom-left": "",
      },

      showOutline: {
        true: "border-2 border-bg-container",
        false: "border-transparent border-0",
      },
    },
    defaultVariants: {
      variant: "solid",
      variantColor: "brand",
      size: "md",
      placement: "top-right",
      showOutline: true,
      isInvisible: false,
    },
    compoundVariants: [
      // solid / color
      {
        variant: "solid",
        variantColor: "muted",
        className: colorVariants.solid.muted,
      },
      {
        variant: "solid",
        variantColor: "brand",
        className: colorVariants.solid.brand,
      },
      {
        variant: "solid",
        variantColor: "accent",
        className: colorVariants.solid.accent,
      },
      {
        variant: "solid",
        variantColor: "success",
        className: colorVariants.solid.success,
      },
      {
        variant: "solid",
        variantColor: "warning",
        className: colorVariants.solid.warning,
      },
      {
        variant: "solid",
        variantColor: "destructive",
        className: colorVariants.solid.destructive,
      },
      // flat / color
      {
        variant: "flat",
        variantColor: "muted",
        className: colorVariants.flat.muted,
      },
      {
        variant: "flat",
        variantColor: "brand",
        className: colorVariants.flat.brand,
      },
      {
        variant: "flat",
        variantColor: "accent",
        className: colorVariants.flat.accent,
      },
      {
        variant: "flat",
        variantColor: "success",
        className: colorVariants.flat.success,
      },
      {
        variant: "flat",
        variantColor: "warning",
        className: colorVariants.flat.warning,
      },
      {
        variant: "flat",
        variantColor: "destructive",
        className: colorVariants.flat.destructive,
      },

      // isOneChar / size
      {
        isOneChar: true,
        size: "sm",
        className: "w-4 h-4 min-w-4 min-h-4",
      },
      {
        isOneChar: true,
        size: "md",
        className: "w-5 h-5 min-w-5 min-h-5",
      },
      {
        isOneChar: true,
        size: "lg",
        className: "w-6 h-6 min-w-6 min-h-6",
      },
      // isDot / size
      {
        isDot: true,
        size: "sm",
        className: "w-2 h-2 min-w-2 min-h-2",
      },
      {
        isDot: true,
        size: "md",
        className: "w-2 h-2 min-w-2 min-h-2",
      },
      {
        isDot: true,
        size: "lg",
        className: "w-2 h-2 min-w-2 min-h-2",
      },
      // placement
      {
        placement: "top-right",
        className: "top-[5%] right-[5%] translate-x-1/2 -translate-y-1/2",
      },
      {
        placement: "top-left",
        className: "top-[5%] left-[5%] -translate-x-1/2 -translate-y-1/2",
      },
      {
        placement: "bottom-right",
        className: "bottom-[5%] right-[5%] translate-x-1/2 translate-y-1/2",
      },
      {
        placement: "bottom-left",
        className: "bottom-[5%] left-[5%] -translate-x-1/2 translate-y-1/2",
      },
    ],
  },
);

export interface ChipProps extends VariantProps<typeof ChipVariants> {
  /**
   * Ref to the DOM node.
   */
  ref?: RefObject<HTMLSpanElement | null>;
  /**
   * The children of the chip.
   */
  children: ReactNode;
  /**
   * The content of the chip. The chip will be rendered relative to its children.
   */
  content?: string | number | ReactNode;

  className?: string;
  /**
   * @example
   * ```ts
   * <Chip classNames={{
   *    base:"base-classes", // wrapper
   *    chip: "chip-classes",
   * }} />
   * ```
   */
}

const Chip = forwardRef<HTMLSpanElement, ChipProps>((props, ref) => {
  const { children, content, className, ...otherProps } = props;

  const { getChipProps } = useChip({ ...otherProps, content });

  return (
    <div
      className={cn(
        "relative inline-flex h-fit w-fit shrink-0 overflow-visible",
        className,
      )}
    >
      {children}
      <span ref={ref} {...getChipProps()}>
        {content}
      </span>
    </div>
  );
});

Chip.displayName = "Chip";

export default Chip;

type useChipProps = Omit<ChipProps, "children">;
interface DOMElement extends Element, HTMLOrSVGElement {}

type DataAttributes = Record<string, any>;

type DOMAttributes<T = DOMElement> = React.AriaAttributes &
  React.DOMAttributes<T> &
  DataAttributes & {
    id?: string;
    role?: React.AriaRole;
    tabIndex?: number;
    style?: React.CSSProperties;
  };

type Merge<M, N> = N extends Record<string, unknown> ? M : Omit<M, keyof N> & N;

type PropGetter<P = Record<string, unknown>, R = DOMAttributes> = (
  props?: Merge<DOMAttributes, P>,
  ref?: React.Ref<any>,
) => R & React.RefAttributes<any>;

function useChip(props: useChipProps) {
  const {
    content,
    className,
    isOneChar,
    isDot,
    variant,
    variantColor,
    isInvisible,
    size,
    placement,
    showOutline,
    ...otherProps
  } = props;

  const isOneCharProp = useMemo(
    () => String(content)?.length === 1 || isOneChar,
    [content, isOneChar],
  );

  const isDotProp = useMemo(
    () => String(content)?.length === 0 || isDot,
    [content, isDot],
  );

  const getChipProps: PropGetter = () => {
    return {
      className: cn(
        ChipVariants({
          variant: variant,
          variantColor: variantColor,
          size: size,
          placement: placement,
          showOutline: showOutline,
          isOneChar: isOneCharProp,
          isDot: isDotProp,
        }),
        className,
      ),
      "data-invisible": isInvisible,
      ...otherProps,
    };
  };

  return {
    isInvisible: isInvisible,
    getChipProps,
  };
}
